
.tab {
    opacity: 1 !important;
    color: var(--tabColor);
    border-radius: 4px !important;
}

.selectedTab {
    color: var(--appBar) !important;
    background-color: var(--selectedTabColor) !important;
    border-bottom: 2px solid var(--selectedTabColor) !important;
}

.hasErrors {
    color: var(--error) !important;
}

.selectedTab.hasErrors {
    color: var(--error) !important;
}

